import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '../utils/apiRouters';

const getHistoryTreePlanning = (body) => {
	const url = `${ApiRouters.MANAGEMENT_HISTORY_TREE_PlANNING}`;
	return api.get(url, { params: body });
};

const createHistoryTreePlanning = (body) => {
	const url = `${ApiRouters.MANAGEMENT_HISTORY_TREE_PlANNING}`;
	return api.post(url, body);
};

const updateHistoryTreePlanning = (id, body) => {
	const url = `${ApiRouters.MANAGEMENT_HISTORY_TREE_PlANNING}/${id}`;
	return api.put(url, body);
};

const deleteHistoryTreePlanning = (id) => {
	const url = `${ApiRouters.MANAGEMENT_HISTORY_TREE_PlANNING}/${id}`;
	return api.delete(url);
};

const HistoryTreePlanningService = {
	getHistoryTreePlanning,
	createHistoryTreePlanning,
	deleteHistoryTreePlanning,
	updateHistoryTreePlanning,
};

export default HistoryTreePlanningService;
