const actions = {
	GET_HISTORY_TREE_PLANNING: 'GET_HISTORY_TREE_PLANNING',
	GET_HISTORY_TREE_PLANNING_SUCCESS: 'GET_HISTORY_TREE_PLANNING_SUCCESS',
	GET_HISTORY_TREE_PLANNING_ERROR: 'GET_HISTORY_TREE_PLANNING_ERROR',

	EXPORT_HISTORY_TREE_PLANNING: 'EXPORT_HISTORY_TREE_PLANNING',
	EXPORT_HISTORY_TREE_PLANNING_SUCCESS: 'EXPORT_HISTORY_TREE_PLANNING_SUCCESS',

	GET_HISTORY_TREE_PLANNING_DETAIL: 'GET_HISTORY_TREE_PLANNING_DETAIL',
	GET_HISTORY_TREE_PLANNING_DETAIL_SUCCESS: 'GET_HISTORY_TREE_PLANNING_DETAIL_SUCCESS',
	GET_HISTORY_TREE_PLANNING_DETAIL_ERROR: 'GET_HISTORY_TREE_PLANNING_DETAIL_ERROR',

	CREATE_HISTORY_TREE_PLANNING: 'CREATE_HISTORY_TREE_PLANNING',
	UPDATE_HISTORY_TREE_PLANNING: 'UPDATE_HISTORY_TREE_PLANNING',
	DELETE_HISTORY_TREE_PLANNING: 'DELETE_HISTORY_TREE_PLANNING',

	//#region CRUD
	
	getHistoryTreePlanning: (body) => ({
		type: actions.GET_HISTORY_TREE_PLANNING,
		body,
	}),


	exportHistoryTreePlanning: (body, cbError) => ({
		type: actions.EXPORT_HISTORY_TREE_PLANNING,
		body,
		cbError,
	}),

	createHistoryTreePlanning: (body, cbSuccess, cbError) => ({
		type: actions.CREATE_HISTORY_TREE_PLANNING,
		body,
		cbSuccess,
		cbError,
	}),
	updateHistoryTreePlanning: (id, body, cbSuccess, cbError) => ({
		type: actions.UPDATE_HISTORY_TREE_PLANNING,
		id,
		body,
		cbSuccess,
		cbError,
	}),
	deleteHistoryTreePlanning: (id, cbSuccess, cbError) => ({
		type: actions.DELETE_HISTORY_TREE_PLANNING,
		id,
		cbSuccess,
		cbError,
	}),
	getHistoryTreePlanningDetail: (id) => ({
		type: actions.DELETE_HISTORY_TREE_PLANNING,
		payload: { id },
	}),
};

export default actions;
