import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { toastMessage } from '../../constants/Models';
import HistoryTreePlanningService from '../../services/historyTreePlanning';

//#region CRUD
export function* getTimeHistory() {
	yield takeLatest(actions.GET_TIME_TIME_HISTORY, function* ({ body }) {
		try {		
			const response = yield call(HistoryTreePlanningService.getHistoryTreePlanning, body);
			if (response?.data) {
				yield put({
					type: actions.GET_UPDATE_TIME_HISTORY_SUCCESS,
					payload: response.data,
				});
			}
		} catch (e) {}
	});
}

export function* updateTimeHistory() {
	yield takeLatest(actions.GET_UPDATE_TIME_HISTORY, function* ({ id, body, cbSuccess, cbError }) {
		
		try {
			const response = yield call(HistoryTreePlanningService.updateHistoryTreePlanning, id, body);
			if (response?.data) {
				cbSuccess(toastMessage.updateSuccess);
			}
		} catch (e) {
			cbError(toastMessage.updateError);
		}
	});
}



export default function* rootSaga() {
	
	yield all([fork(getTimeHistory), fork(updateTimeHistory)]);
}
