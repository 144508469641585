import actions from './actions';

const initState = {
	HistoryTreePlanning: [],
	exportHistoryTreePlanning: [],
	HistoryTreePlanningDetail: {},
	loading: false,
};

export default function HistoryTreePlanningReducer(state = initState, action) {
	switch (action.type) {
		//#region CRUD
		case actions.GET_HISTORY_TREE_PLANNING_SUCCESS:
			return { ...state, loading: false, HistoryTreePlanning: action.payload };
		case actions.UPDATE_HISTORY_TREE_PLANNING:			
			return { ...state, loading: false, HistoryTreePlanning: action.payload };
		default:
			return state;
	}
}
