const actions = {
	GET_TIME_TIME_HISTORY: 'GET_TIME_TIME_HISTORY',
	GET_UPDATE_TIME_HISTORY: 'GET_UPDATE_TIME_HISTORY',
	GET_UPDATE_TIME_HISTORY_SUCCESS: 'GET_UPDATE_TIME_HISTORY_SUCCESS',
	GET_UPDATE_TIME_HISTORY_ERROR: 'GET_UPDATE_TIME_HISTORY_ERROR',

	EXPORT_UPDATE_TIME_HISTORY: 'EXPORT_UPDATE_TIME_HISTORY',
	EXPORT_UPDATE_TIME_HISTORY_SUCCESS: 'EXPORT_UPDATE_TIME_HISTORY_SUCCESS',

	GET_UPDATE_TIME_HISTORY_DETAIL: 'GET_UPDATE_TIME_HISTORY_DETAIL',
	GET_UPDATE_TIME_HISTORY_DETAIL_SUCCESS: 'GET_UPDATE_TIME_HISTORY_DETAIL_SUCCESS',
	GET_UPDATE_TIME_HISTORY_DETAIL_ERROR: 'GET_UPDATE_TIME_HISTORY_DETAIL_ERROR',

	CREATE_UPDATE_TIME_HISTORY: 'CREATE_UPDATE_TIME_HISTORY',
	UPDATE_UPDATE_TIME_HISTORY: 'UPDATE_UPDATE_TIME_HISTORY',
	DELETE_UPDATE_TIME_HISTORY: 'DELETE_UPDATE_TIME_HISTORY',

	//#region CRUD
	
	getTimeHistory: (body) => ({
		type: actions.GET_UPDATE_TIME_HISTORY,
		body,
	}),


	exportimeHistory: (body, cbError) => ({
		type: actions.EXPORT_UPDATE_TIME_HISTORY,
		body,
		cbError,
	}),

	createTimeHistory: (body, cbSuccess, cbError) => ({
		type: actions.CREATE_UPDATE_TIME_HISTORY,
		body,
		cbSuccess,
		cbError,
	}),
	updateTimeHistory: (id, body, cbSuccess, cbError) => ({
		type: actions.UPDATE_UPDATE_TIME_HISTORY,
		id,
		body,
		cbSuccess,
		cbError,
	}),
	deleteTimeHistory: (id, cbSuccess, cbError) => ({
		type: actions.DELETE_UPDATE_TIME_HISTORY,
		id,
		cbSuccess,
		cbError,
	}),
	getUpdateTimeHistoryDetail: (id) => ({
		type: actions.DELETE_UPDATE_TIME_HISTORY,
		payload: { id },
	}),
};

export default actions;
